<template>
  <div class="caseDetail">
    <PageTitle ttl-en="DOWNLOAD" ttl-jp="" />
    <div class="inner">
      <div class="download__top">
        <h1 class="download__top__ttl js-scroll">
          資料ダウンロード
        </h1>
      </div>
      <ul class="download__contents js-scroll">
        <li v-for="(item, index) in downloads"
          :data-index="index"
          :key="item.ttl"
          class="topics__item"
        >
          <a :href="`/genbashiko/upload/download_pdf/`+item.fileName" target="_blank">
            <img :src="`/genbashiko/upload/download_img/` + item.imageName" alt="" />
            <p class="txt">
              {{item.title}}
            </p>
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import PageTitle from '@/components/PageTitle.vue'
import scrollAnimMixin from '../../mixins/scrollAnimMixin.js'
import axios from 'axios'

export default {
  mixins: [scrollAnimMixin],
  data() {
    return {
      downloads:[]
    };
  },
  components: {
    PageTitle
  },
  head: {
    title: {
      inner: '資料ダウンロード'
    },
    meta: [
      { property: 'og:title', content: '資料ダウンロード｜GENBA SHIKO' },
      {
        name: 'description',
        content:
          '自動車業界向けの検査照明に関する各種資料をダウンロードすることができます。'
      },
      {
        property: 'og:description',
        content:
          '自動車業界向けの検査照明に関する各種資料をダウンロードすることができます。'
      },
      {
        property: 'og:url',
        content: 'https://luci.co.jp/genbashiko/download'
      },
      { property: 'og:type', content: 'article' }
    ]
  },
  mounted:function(){
    this.loadData();
  },
  methods: {
    loadData: function(){
      let path ="/genbashiko/upload/downloadlist.xml";
      axios.get(path, {
        responseType: "document",
        transformResponse: (data) => {
          let items = data.querySelectorAll("item[type='download']")
          if(items.length>0){ 
            items.forEach((v)=>{
              let ttl = v.querySelector("title").textContent;
              let fileName = v.querySelector("filename").textContent;
              let imageName = v.querySelector("imagename").textContent;
              this.downloads.push(
                {"title":ttl,
                "fileName":fileName,
                "imageName":imageName
                }
              )
            })
          }
        }
      }).catch(() => {
        console.log(`${path}のロードに失敗しました。`);
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.download {
  padding-bottom: 42px;
  &__top {
    margin-top: 80px;
    &__ttl {
      font-size: 3.6rem;
      &::after {
        content: '';
        display: block;
        background: $orColor;
        width: 40px;
        height: 4px;
        margin: 30px auto 0;
      }
      &.js-scroll {
        transform: translateY(0);
      }
    }
  }
  &__contents {
    display: flex;
    flex-wrap: wrap;
    border-left: 1px solid #e0dcd3;
    margin-top: 40px;
    li {
      flex: 0 1 33%;
      width: 33%;
      border: 1px solid #e0dcd3;
      border-left: none;
      border-top: none;
      padding: 40px 76px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      &:nth-of-type(1),
      &:nth-of-type(2),
      &:nth-of-type(3) {
        border-top: 1px solid #e0dcd3;
      }
      img {
        box-shadow: 0 3px 10px rgba(0, 0, 0, 0.15);
      }
      a {
        display: flex;
        align-items: center;
        flex-direction: column;
        .txt {
          margin-top: 30px;
          font-size: 1.4rem;
          color: $orColor;
          font-weight: 700;
          display: flex;
          align-items: center;
          &::after {
            content: '';
            width: 20px;
            height: 20px;
            display: block;
            margin-left: 6px;
            flex: 0 0 20px;
            background: url('/img/common/icon-pdf.png') center/cover no-repeat;
          }
        }
        &:hover {
          opacity: 0.7;
          transition: opacity 0.3s ease-out;
        }
      }
    }
  }
}
@media only screen and (max-width: 768px) {
  .download {
    padding-bottom: 10px;
    &__top {
      margin-top: 40px;
      &__ttl {
        font-size: 2.1rem;
      }
    }
    &__contents {
      li {
        flex: 0 1 50%;
        width: 50%;
        padding: 25px 20px;
        &:nth-of-type(3) {
          border-top: none;
        }
        a {
          img {
            width: 90%;
          }
          .txt {
            font-size: 1.2rem;
            margin-top: 20px;
            &::after {
              margin-left: 3px;
              flex: 0 0 10px;
              width: 10px;
              height: 10px;
            }
          }
        }
      }
    }
  }
}
</style>
